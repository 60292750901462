import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import { gsap } from "gsap";

import { animationEnter, animationLeave } from './animations';

barba.use(barbaPrefetch);

barba.hooks.before(() => {
  document.querySelector('html').classList.add('is-transitioning');
});

barba.hooks.after(() => {
  document.querySelector('html').classList.remove('is-transitioning');
});

barba.hooks.enter(() => {
  window.scrollTo(0, 0);
});

barba.init({
  preventRunning: true,
  schema: {
    prefix: 'data-animate'
  },
  views: [{
    namespace: 'partners',
    afterEnter() {
      gsap.from('.animateIn', { autoAlpha: 0, yPercent: 50, duration: .45, stagger: .1, delay: .45 }, '<-.3');
    }
  }],
  transitions: [{
    name: 'global',
    once(data) {
      gsap.from('#section-header a', { autoAlpha: 0, yPercent: -100, duration: .2, stagger: .1, delay: .2 });
      animationEnter(data.next.container);
    },
    leave(data) {
      animationLeave(data.current.container);
    },
    enter(data) {
      animationEnter(data.next.container);
    },
  }]
});

class StickyHeader extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.header = document.getElementById('section-header');
    this.headerBounds = {};
    this.currentScrollTop = 0;
    this.preventReveal = false;

    this.onScrollHandler = this.onScroll.bind(this);
    this.hideHeaderOnScrollUp = () => this.preventReveal = true;

    this.addEventListener('preventHeaderReveal', this.hideHeaderOnScrollUp);
    window.addEventListener('scroll', this.onScrollHandler, false);

    this.createObserver();
  }

  disconnectedCallback() {
    this.removeEventListener('preventHeaderReveal', this.hideHeaderOnScrollUp);
    window.removeEventListener('scroll', this.onScrollHandler);
  }

  createObserver() {
    let observer = new IntersectionObserver((entries, observer) => {
      this.headerBounds = entries[0].intersectionRect;
      observer.disconnect();
    });

    observer.observe(this.header);
  }

  onScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > this.currentScrollTop && scrollTop > this.headerBounds.bottom) {
      requestAnimationFrame(this.hide.bind(this));
    } else if (scrollTop < this.currentScrollTop && scrollTop > this.headerBounds.bottom) {
      if (!this.preventReveal) {
        requestAnimationFrame(this.reveal.bind(this));
      } else {
        window.clearTimeout(this.isScrolling);

        this.isScrolling = setTimeout(() => {
          this.preventReveal = false;
        }, 66);

        requestAnimationFrame(this.hide.bind(this));
      }
    } else if (scrollTop <= this.headerBounds.top) {
      requestAnimationFrame(this.reset.bind(this));
    }


    this.currentScrollTop = scrollTop;
  }

  hide() {
    this.header.classList.add('section-header-hidden', 'section-header-sticky');
    // this.closeMenuDisclosure();
  }

  reveal() {
    this.header.classList.add('section-header-sticky', 'animate');
    this.header.classList.remove('section-header-hidden');
  }

  reset() {
    this.header.classList.remove('section-header-hidden', 'section-header-sticky', 'animate');
  }
}

customElements.define('sticky-header', StickyHeader);
